'use strict';
var toastr = require('toastr');
var isMobile = !($(window).width() >= 768);


$(document).ready(function () {
    $('.js-verify-phone-btn').on('click', function (e) {
        e.preventDefault();
        $('.resendSms').addClass('disabled');
        $('.timerText').show();
        var gTagData;
        var url = $(this).data('verification-url');
        var $form = $('#registeration-form-otp');
        var countryCode = $form.find('#countrycode').val();
        var phoneValue = $form.find('.js-phone-mobile').val();
        var $methodDOM = $form.find('.js-otp-method').find('li.active');
        var mode = 'whatsapp';
        if ($methodDOM.length > 0) {
            mode = $methodDOM.find('a').data('method');
        }
        var phoneNumber = countryCode + phoneValue;

        var data = {
            phoneNumber: phoneNumber,
            countryCode: countryCode,
            mode: mode
        };
        if ($(this).hasClass('resendSms')) {
            data = {
                phoneNumber: phoneNumber
            };
        }
        var csrfField = $('.js-csrf');
        data[csrfField.attr('name')] = csrfField.val();
        gTagData = {
            phoneNumber: phoneNumber
        };
        // eslint-disable-next-line no-undef
        grecaptcha.ready(function () {
            // eslint-disable-next-line no-undef
            grecaptcha.execute(resources.recaptchaSiteKey, { action: 'signup' }).then(function (token) {
                data.token = token;
                $.ajax({
                    url: url,
                    type: 'POST',
                    data: data,
                    success: function (response) {
                        $form.spinner().stop();
                        gTagData = {
                            phoneNumber: phoneNumber,
                            message: response.message,
                            // email: $form.find('#registration-form-email')[0].value,
                            firstname: $form.find('#registration-form-fname')[0].value,
                            lastname: $form.find('#registration-form-lname')[0].value,
                            // gender: $form.find('.radio-button-group .active').attr('data-type'),
                            countryCode: countryCode,
                            phone: phoneValue
                        };
                        if (!response.success) {
                            // eslint-disable-next-line no-unused-expressions, no-alert
                            isMobile ? alert(response.message) : toastr.error(response.message);
                            $(document).trigger('verification:error', gTagData);
                            return;
                        }
                        $(document).trigger('verification:success', gTagData);

                        $form.find('#registeration-step-1').fadeOut(50, function () {
                            var $num = phoneValue;
                            var $code = countryCode;
                            $('.new-login-tabs').fadeOut();
                            $form.find('#registeration-step-2').find('.js-add-number').text($code + $num);
                            $form.find('#registeration-step-2').find('.js-otp-text').text(response.message);
                            $form.find('#registeration-step-2').fadeIn(100);
                            $form.find('.loginBox').hide();
                            $form.find('.nomargi-flex .js-registeration-goback-otp').show();
                            window.scrollTo(0, 0);
                            var timer = $form.find('.resendSmsRegister').data('enableforseconds');

                            var counter = timer;
                            var interval = setInterval(function () {
                                counter--;
                                // Display 'counter' wherever you want to display it.
                                if (counter <= 0) {
                                    clearInterval(interval);
                                    $form.find('.resendSmsRegister').removeClass('disabled');
                                    $form.find('.timerText').hide();
                                    $form.find('.timerText span').html(timer);
                                    return;
                                    // eslint-disable-next-line no-else-return
                                } else {
                                    $form.find('.timerText span').text(counter);
                                }
                            }, 1000);
                        });
                        $form.spinner().stop();
                        // var token = response.token;
                        // $('.otp-token').val(token);
                        $form.find('.js-btn-save').removeAttr('disabled').removeClass('is-disabled');
                    },
                    error: function (error) {
                        $('.js-register-btn').prop('disabled', false);
                        $('.js-register-btn').removeClass('is-disabled');

                        // eslint-disable-next-line no-unused-expressions, no-alert
                        isMobile ? alert(error) : toastr.error(error);
                    }
                });
            });
        });
    });

    $('.js-verify-phone-btn-enoc').on('click', function (e) {
        e.preventDefault();
        if ($('input#agreeTerms').is(':checked')) {
            $('.resendSms').addClass('disabled');
            $('.timerText').show();
            var gTagData;
            var url = $(this).data('verification-url');
            var $form = $('#partner-register');
            var countryCode = $('#countrycode').val();
            var email = $('#registration-form-email').val();
            var phoneValue = $('.js-phone-mobile').val();
            var phoneNumber = countryCode + phoneValue;
            var enocCode = $('#partner-register input[name="enocCode"]').val();
            var codes = enocCode !== '' ? [enocCode] : [];
            var data = {
                phoneNumber: phoneNumber,
                email: email,
                countryCode: countryCode,
                enocCodes: JSON.stringify(codes)
            };
            if ($(this).hasClass('resendSms')) {
                data = {
                    phoneNumber: phoneNumber
                };
            }
            var csrfField = $('.js-csrf');
            data[csrfField.attr('name')] = csrfField.val();
            gTagData = {
                phoneNumber: phoneNumber
            };
            // eslint-disable-next-line no-undef
            grecaptcha.ready(function () {
            // eslint-disable-next-line no-undef
                grecaptcha.execute(resources.recaptchaSiteKey, { action: 'signup' }).then(function (token) {
                    data.token = token;
                    $.ajax({
                        url: url,
                        type: 'POST',
                        data: data,
                        success: function (response) {
                            gTagData = {
                                phoneNumber: phoneNumber,
                                message: response.message,
                                email: $form.find('#registration-form-email')[0].value,
                                firstname: $form.find('#registration-form-fname')[0].value,
                                lastname: $form.find('#registration-form-lname')[0].value,
                                gender: $form.find('.radio-button-group .active').attr('data-type'),
                                countryCode: $form.find('.country-code-container .custom-select span:first').text(),
                                phone: $form.find('.js-phone-mobile')[0].value
                            };
                            if (!response.success) {
                            // eslint-disable-next-line no-unused-expressions
                                isMobile ? alert(response.message) : toastr.error(response.message);
                                $(document).trigger('verification:error', gTagData);
                                return;
                            }
                            $(document).trigger('verification:success', gTagData);

                            $('#registeration-step-1').fadeOut(50, function () {
                                var $num = $('.js-phone-mobile').val();
                                var $code = $('#countrycode').val();
                                $('.new-login-tabs').fadeOut();
                                $('#registeration-step-2').find('.js-add-number').text($code + $num);
                                $('#registeration-step-2').fadeIn(100);
                                $('.loginBox').hide();
                                $('.nomargi-flex .js-registeration-goback-otp').show();
                                window.scrollTo(0, 0);
                                var timer = $('.resendSms').data('enableforseconds');

                                var counter = timer;
                                var interval = setInterval(function () {
                                    counter--;
                                    // Display 'counter' wherever you want to display it.
                                    if (counter <= 0) {
                                        clearInterval(interval);
                                        $('.resendSms').removeClass('disabled');
                                        $('.timerText').hide();
                                        $('.timerText span').html(timer);
                                        return;
                                    // eslint-disable-next-line no-else-return
                                    } else {
                                        $('.timerText span').text(counter);
                                    }
                                }, 1000);
                            });

                            // var token = response.token;
                            // $('.otp-token').val(token);
                            $('.js-btn-save').removeAttr('disabled').removeClass('is-disabled');
                        },
                        error: function (error) {
                            $('.js-register-btn').prop('disabled', false);
                            $('.js-register-btn').removeClass('is-disabled');

                            // eslint-disable-next-line no-unused-expressions
                            isMobile ? alert(error) : toastr.error(error);
                        }
                    });
                });
            });
        } else {
            alert(resources.Gloabl$agreeTerms);
        }
    });

    $('.resendSms').on('click', function (e) {
        e.preventDefault();
        $('.resendSms').addClass('disabled');
        $('.timerText').show();
        var gTagData;
        var url = $(this).data('verification-url');
        var countryCode = $('#countrycode').val();
        var email = $('#registration-form-email').val();
        var phoneValue = $('.js-phone-mobile').val();
        var phoneNumber = countryCode + phoneValue;
        var $methodDOM = $('.js-otp-method').find('li.active');
        var mode = 'sms';
        if ($methodDOM.length > 0) {
            mode = $methodDOM.find('a').data('method');
        }
        var data = {
            phoneNumber: phoneValue,
            email: email,
            mode: mode,
            countryCode: countryCode
        };
        if ($(this).hasClass('resendSms')) {
            data = {
                phoneNumber: phoneValue,
                resendEmail: email,
                mode: mode,
                countryCode: countryCode
            };
        }
        var csrfField = $('.js-csrf');
        data[csrfField.attr('name')] = csrfField.val();
        gTagData = {
            phoneNumber: phoneNumber
        };
        // eslint-disable-next-line no-undef
        grecaptcha.ready(function () {
            // eslint-disable-next-line no-undef
            grecaptcha.execute(resources.recaptchaSiteKey, { action: 'signup' }).then(function (token) {
                data.token = token;
                $.ajax({
                    url: url,
                    type: 'POST',
                    data: data,
                    success: function (response) {
                        gTagData = {
                            phoneNumber: phoneNumber,
                            message: response.message
                        };
                        if (!response.success) {
                            // eslint-disable-next-line no-unused-expressions, no-alert
                            isMobile ? alert(response.message) : toastr.error(response.message);
                            $('.resendSms').removeClass('disabled');
                            $('.timerText').hide();
                            $(document).trigger('verification:error', gTagData);
                            return;
                        }

                        // eslint-disable-next-line no-unused-expressions, no-alert
                        isMobile ? alert(response.message) : toastr.error(response.message);
                        $(document).trigger('verification:success', gTagData);

                        $('#registeration-step-1').fadeOut(50, function () {
                            $('#registeration-step-2').fadeIn(100);
                            $('.loginBox').hide();
                            $('.new-login-tabs').fadeOut();
                            var timer = $('.resendSms').data('enableforseconds');

                            var counter = timer;
                            var interval = setInterval(function () {
                                counter--;
                                // Display 'counter' wherever you want to display it.
                                if (counter <= 0) {
                                    clearInterval(interval);
                                    $('.resendSms').removeClass('disabled');
                                    $('.timerText').hide();
                                    $('.timerText span').html(timer);
                                    return;
                                    // eslint-disable-next-line no-else-return
                                } else {
                                    $('.timerText span').text(counter);
                                }
                            }, 1000);
                        });


                        // var token = response.token;
                        // $('.otp-token').val(token);
                        $('.js-btn-save').removeAttr('disabled').removeClass('is-disabled');
                    },
                    error: function (error) {
                        $('.js-register-btn').prop('disabled', false);
                        $('.js-register-btn').removeClass('is-disabled');

                        // eslint-disable-next-line no-unused-expressions, no-alert
                        isMobile ? alert(error) : toastr.error(error);
                    }
                });
            });
        });
    });
});
